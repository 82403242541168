import React, {Component, Fragment} from 'react';
import {isMobile} from 'react-device-detect';
import { Button, Modal, ModalFooter } from 'reactstrap';


class Marketing extends Component {

    constructor(props) {
        super(props);
        this.state = {
          modal: true,
          nestedModal: false,
          closeAll: false
        };
    
        this.toggle = this.toggle.bind(this);
        this.toggleNested = this.toggleNested.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
      }
    
      componentDidUpdate = () =>{
            window.location = 'https://www.playdoit.mx/?modal=registration'
      }  
    toggle() {
        this.setState({
          modal: !this.state.modal
        });
      }
    
    toggleNested() {
        this.setState({
          nestedModal: !this.state.nestedModal,
          closeAll: false
        });
      }
    
    toggleAll() {
        this.setState({
          nestedModal: !this.state.nestedModal,
          closeAll: true
        });
      }
    
    renderContent = () => {
        if (isMobile) {
            return (
                <Fragment>
                    <Button className="btn btn-aceptM" href='https://www.playdoit.mx/?modal=registration'>Si, Soy mayor de edad</Button>
                    <Button className="btn btn-rejectM">No, Soy menor de edad</Button>
                </Fragment>
            )    
        }else{
            return (
                <Fragment>
                    <Button className="btn btn-acept" href='https://www.playdoit.mx/?modal=registration'>Si, Soy mayor de edad</Button>
                    <Button className="btn btn-reject">No, Soy menor de edad</Button>
                </Fragment>
            )
        }
        
    }

    renderSteps = () => {
        if(isMobile) {
            return (
                <Fragment>
                    <div className="col-lg-4">
                    <div className="media">
                        <img src="https://www.playdoit.mx/cms/online/1.png" className="align-self-center mr-3 img-fluid img-fluidM" alt="..."/>
                        <div className="media-body">
                            <h2 className="mt-4 h-style h-style-M">Regístrate</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="media">
                            <img src="https://www.playdoit.mx/cms/online/2.png" className="align-self-center mr-3 img-fluid img-fluidM" alt="..."/>
                            <div className="media-body">
                                <h2 className="mt-4 h-style">Completa el registro</h2>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-4">
                    <div className="media">
                            <img src="https://www.playdoit.mx/cms/online/3.png" className="align-self-center mr-3 img-fluid img-fluidM" alt="..."/>
                            <div className="media-body">
                                <h2 className="mt-4 h-style h2-style h-style-M">Completa el registro</h2>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }else {
            return (
                <Fragment>
                    <div className="col-lg-4">
                    <div className="media">
                        <img src="https://www.playdoit.mx/cms/online/1.png" className="align-self-center mr-3 img-fluid" alt="..."/>
                        <div className="media-body">
                            <h2 className="mt-5 h-style">Regístrate</h2>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="media">
                            <img src="https://www.playdoit.mx/cms/online/2.png" className="align-self-center mr-3 img-fluid" alt="..."/>
                            <div className="media-body">
                                <h2 className="mt-4 h-style">Completa el registro</h2>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-4">
                    <div className="media">
                            <img src="https://www.playdoit.mx/cms/online/3.png" className="align-self-center mr-3 img-fluid" alt="..."/>
                            <div className="media-body">
                                <h2 className="mt-4 h-style h2-style">Entra a ganar</h2>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }


    renderButton = () => {
        if(isMobile){
            return(
                <Fragment>
                    <div className="mx-auto">
                        <div className="mb-2">
                        <Button className='btn btn-success btn-register' href='https://www.playdoit.mx/register'>¡Regístrate Ahora!</Button>
                            <p className='mt-5 mx-auto text-center p-decoration'><a href='https://www.playdoit.mx/es/terminosycondiciones' className='mt-1'>Términos y Condiciones</a></p>
                        </div>
                    </div>
                </Fragment>
            )
        }else{
            return (
                <Fragment>
                    <div className="col-4 mx-auto">
                        <div className="mb-2">
                            <Button className='btn btn-success btn-register' href='https://www.playdoit.mx/register'>¡Regístrate Ahora!</Button>
                            <p className='mt-5 mx-auto text-center p-decoration'><a href='https://www.playdoit.mx/es/terminosycondiciones' className='mt-1'>Términos y Condiciones</a></p>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    render () {
        return (
            <div className="container marketing">
            <div className="row">
                {this.renderSteps()}
            </div>
            <div className="row mt-5 text-center">
            {this.renderButton()}
            </div>
           
     
            <Modal isOpen={this.state.modal} toggle={this.toggle} className='modal-dialog modal-dialog-centered'>
            <img className="img-fluid img-popup" src='https://www.playdoit.mx/cms/online/popup.png' alt='Payment Playdoit'/>
                <ModalFooter className='modal-footer popup-content'>
                    {this.renderContent()}
                </ModalFooter>
            </Modal>
         
            
        </div>
        )
    }
}

export default Marketing;